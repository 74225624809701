import React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { menuOptions } from '../../utils/menuOptions';
import { Link } from 'react-router-dom';

export const CTAHero = ({ isFullWidth, textCta }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Button
      as={Link}
      to={menuOptions.EVENTS.path}
      variant='contained'
      color='secondary'
      aria-label='Text CTA'
      fullWidth={isFullWidth}
      size='large'
      sx={{
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'none',
        zIndex: 2,
        positon: 'relative',
        mb: isMobile ? '2em' : '0',
        mt: isMobile ? '2em' : '0',
        fontSize: '1.3rem',
        p: isMobile ? '15px 15px' : '15px 100px',
        mx: '5px',
        borderRadius: '25px',
        transition: 'box-shadow 200ms, background-color 200ms',
      }}>
      {textCta}
    </Button>
  );
};

CTAHero.defaultProps = {
  isFullWidth: true,
  textCta: 'Renta un palco'
};
