import clubAtlante from '../assets/securityFeatures/partnerships/clubAtlante.png';
import clubSantos from '../assets/securityFeatures/partnerships/clubSantos.png';
import fronticket from '../assets/securityFeatures/partnerships/fronticket.png';
import fronton from '../assets/securityFeatures/partnerships/fronton.png';
import mazatlan from '../assets/securityFeatures/partnerships/mazatlan.png';
import seatsAvenue from '../assets/securityFeatures/partnerships/seats.png';
import estadioCiudad from '../assets/securityFeatures/partnerships/logo-cd.png';
import plazaMexico from '../assets/securityFeatures/partnerships/logo-pm.svg';
import oasisGroup from '../assets/securityFeatures/partnerships/logo-oasisgroup.png';
import forbes from '../assets/securityFeatures/partnerships/forbes.png'
import oaseas from '../assets/securityFeatures/partnerships/oaseas.png'

const width = '160px';

export const alianzaData = [
  {
    image: clubAtlante,
    alt: 'Atlante',
    widthImg: width,
  },
  {
    image: oaseas,
    alt: 'Oaseas',
    widthImg: width,
  },
  {
    image: oasisGroup,
    alt: 'Oasis Group',
    widthImg: width,
  },
  {
    image: forbes,
    alt: 'Forbes',
    widthImg: width,
  },
  {
    image: plazaMexico,
    alt: 'Plaza Mexico',
    widthImg: width,
  },
  {
    image: estadioCiudad,
    alt: 'Estadio Ciudad de los Deportes',
    widthImg: width,
  },
  {
    image: clubSantos,
    alt: 'Santos',
    widthImg: width,
  },
  {
    image: fronticket,
    alt: 'Fronticket',
    widthImg: width,
  },
  {
    image: fronton,
    alt: 'Fronton',
    widthImg: width,
  },
  {
    image: mazatlan,
    alt: 'Mazatlan',
    widthImg: width,
  },
  {
    image: seatsAvenue,
    alt: 'Seats Avenue',
    widthImg: width,
  }
];