import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components & Utils
import ViewContainer from '../../components/shared/ViewContainer';
import EventBanner from '../../components/events/EventBanner';
import BoxCard from '../../components/cards/BoxCard';
import FAQSection from '../../components/shared/FAQSection';
import VenueMap from '../../components/events/VenueMap';
import { useEvent, useEventBoxes } from '../../hooks/requests';
import { parseDate } from '../../utils/util';
import HalfCircle from '../../components/shared/HalfCircle';
import EmptyState from '../../components/shared/EmptyState';

// UI
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const cardsForCarousel = () => {
  const cards = [];
  for (let index = 0; index < 12; index++) {
    cards.push(
      <Grid xs={12} sm={6} md={4} lg={3} item key={index}>
        <Skeleton
          key={index}
          variant='rectangular'
          sx={{
            borderRadius: '16px',
            height: { xs: '13.25em', sm: '13.4em', md: '17.3em', lg: '17.4em' },
            width: '100%',
          }}
        />
      </Grid>
    );
  }
  return cards;
};

/**
 * @function Event
 * @author RCH010
 * @version 1.0
 * @description View of an specific event. it shows all the available boxes with
 * the BoxCard component
 * @returns {JSX}
 */
const Event = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const { data: eventData } = useEvent(eventID, {
    cacheTime: 5000,
    onError: () => setIsError(true),
  });

  const { data: eventBoxes } = useEventBoxes(eventID, {
    cacheTime: 5000,
    onError: () => setIsError(true),
  });

  useDocumentTitle(`Oasis App ${eventData?.name ? '- ' + eventData.name : ''}`);
  const venueData = eventData?.venue;

  let boxPositions = [];
  if (eventBoxes) {
    boxPositions = eventBoxes.map((box) => ({
      x: parseFloat(box.position.x),
      y: parseFloat(box.position.y),
      id: box.id,
      ...box,
    }));
  }

  const bannerIconTextItems = [
    {
      id: 1,
      text: eventData?.venue?.venue,
      Icon: <LocationOnIcon color='secondary' />,
    },
    {
      id: 2,
      text: parseDate(eventData?.startsAt || '', 'dayLongMonthYear'),
      Icon: <CalendarTodayIcon color='secondary' />,
    },
    {
      id: 3,
      text: parseDate(eventData?.startsAt || '', 'time'),
      Icon: <AccessTimeIcon color='secondary' />,
    },
  ];

  if (eventData?.numberBoxesAvailable) {
    bannerIconTextItems.push({
      id: 4,
      text: `${eventData.numberBoxesAvailable || ''} palcos disponibles`,
      Icon: <EventSeatIcon color='secondary' />,
    });
  }
  useEffect(() => {
    if (isError) {
      document.title = 'Oasis App - Evento no disponible';
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'googlebot');
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, [isError]);

  return (
    <ViewContainer>
      <HalfCircle isLeftCircle top='250px' left='-2em' maxWidth='unset' />
      <HalfCircle right='-2em' bottom='200px' opacity='.8' maxWidth='unset' />
      {isError ? (
        <Box sx={{ mb: 15, py: 10, px: 3 }}>
          <EmptyState
            title='¡Oh no!'
            subtitle='Parece que este evento no esta disponible. Te invitamos a ver más eventos'
            buttonLabel='Ver eventos'
            onButtonClick={() => navigate('/events')}
          />
        </Box>
      ) : (
        <>
          <EventBanner
            isLoading={!eventData || !eventBoxes}
            pictureUrl={eventData?.pictureUrl}
            title={eventData?.name}
            iconTextItems={bannerIconTextItems}
            goBack={() => navigate('/events')}
          />
          <Box
            sx={{
              px: 3,
              mb: 15,
              maxWidth: 'lg',
              mx: 'auto',
              zIndex: 1,
              position: 'relative',
              width: '100%',
            }}>
            {eventBoxes && eventData ? (
              eventBoxes.length > 0 ? (
                <>
                  <Typography variant='h2'>Palcos y Plateas en renta disponibles</Typography>
                  <Grid
                    container
                    spacing={4}
                    sx={{ py: { xs: 5, md: 10 }, mb: 10 }}>
                    {eventBoxes.map((box) => (
                      <Grid xs={12} sm={6} md={4} lg={3} item key={box.id}>
                        <BoxCard boxData={box} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <EmptyState
                  title='¡Oh no!'
                  subtitle='Por el momento para este evento no hay palcos dispnibles'
                  buttonLabel='Regresar a eventos'
                  onButtonClick={() => navigate('/events')}
                />
              )
            ) : (
              <Grid
                container
                spacing={4}
                sx={{ py: { xs: 5, md: 10 }, mb: 10 }}>
                {cardsForCarousel()}
              </Grid>
            )}
            {eventBoxes && eventData && venueData ? (
              <Box>
                <Typography variant='h2' sx={{ mb: 2 }}>
                Ubicación de los lugares
                </Typography>
                <VenueMap
                  venueImage={venueData.venuePictureUrl}
                  venueHeight={venueData.venuePictureHeight}
                  venueWidth={venueData.venuePictureWidth}
                  boxPositions={boxPositions}
                  activePins
                />
              </Box>
            ) : (
              <Box>
                <Skeleton
                  width='100%'
                  height='5em'
                  variant='text'
                  sx={{ mb: 4 }}
                />
                <Skeleton
                  variant='rectangular'
                  width='100%'
                  sx={{
                    mx: 'auto',
                    maxWidth: 'md',
                    height: { xs: '20em', md: '30em' },
                  }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      <FAQSection />
    </ViewContainer>
  );
};

export default Event;
