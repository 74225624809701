import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box, useTheme } from '@mui/material';
import { getResponsiveObjectForBannerAlianza } from '../../utils/carouselResponsive';
import BannerItem from './BannerItem';
import Carousel from '../carousel/Carousel';

/**
 * @function BannersCarousel
 * @author AxelML
 * @version 1.0
 * @returns {JSX}
 */

const BannerAlianza = ({data, bkDesk, bkTablet, bkTabletSm, bkMobile, itemBanner, dots}) => {
  const theme = useTheme();
  const responsiveCarouselBreakpoints =
    getResponsiveObjectForBannerAlianza(theme, bkDesk, bkTablet, bkTabletSm, bkMobile);

  return(
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          mx: 'auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <Carousel
          autoPlaySpeed={5000}
          arrow={false}
          carouselItems={data}
          Item={itemBanner}
          responsive={responsiveCarouselBreakpoints}
          dots={dots}
          maxW='100%'
        />
      </Box>
    </Box>
  );
};

BannerAlianza.defaultProps = {
  data: [],
  bkDesk: 1, 
  bkTablet: 1,
  bkMobile: 1,
  itemBanner: BannerItem,
  dots: true
};

BannerAlianza.propTypes = {
  data: PropTypes.array,
  bkDesk: PropTypes.number, 
  bkTablet: PropTypes.number,
  bkMobile: PropTypes.number,
  itemBanner: PropTypes.any,
  dots: PropTypes.bool
}

export default BannerAlianza;