import React from 'react';
import PropTypes from 'prop-types';
//import { useSearchParams } from 'react-router-dom';

// Components & Utils
import EventCard from '../../components/cards/EventCard';
import CardsRowSkeleton from '../../components/shared/skeletons/CardsRowSkeleton';

// Utils

import { getResponsiveObjectForEvents } from '../../utils/carouselResponsive';
import { useEvents } from '../../hooks/requests';

import { useNavigate } from 'react-router-dom';

// UI
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Returns an array with three rows of skeleton cards
const numberOfSkeletonRows = 3;
const skeletonRows = (responsiveCarouselBreakpoints) => {
  const rows = [];
  for (let index = 0; index < numberOfSkeletonRows; index++) {
    rows.push(
      <CardsRowSkeleton
        key={index}
        responsive={responsiveCarouselBreakpoints}
      />
    );
  }
  return rows;
};

/**
 * @function EventsByIndex
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const EventsByIndex = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const responsiveCarouselBreakpoints = getResponsiveObjectForEvents(theme);
  //const [, setSearchParams] = useSearchParams();

  const onErrorFetchingEvents = (e) => {
    console.log(e);
  };

  const { data: events } = useEvents({
    cacheTime: 5000,
    onError: onErrorFetchingEvents,
  });

  /*const onSearchEvents = (category) => {
    setSearchParams({ category: category.id, name: category.category });
  };*/

  return (
    <>
      <Stack
        sx={{ position: 'relative', zIndex: 1 }}
        spacing={{ xs: 6, md: 10 }}
        direction='column'>
        {events ? (
          events.slice(0, 1).map((category, index) => (
            <Box key={category.id}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ maxWidth: 'lg', mx: 'auto', mb: 4, px: 3 }}>
                <Typography variant='h2'>{category.category}</Typography>
                <Button
                  variant='outlined'
                  color='secondary'
                  aria-label='Ver todos'
                  sx={{ textTransform: 'none' }}
                  onClick={() => navigate('/events?category=10&name=Eventos+destacados')}>
                  Ver todos
                </Button>
              </Stack>
              <Box
                sx={{
                  maxWidth: 'lg',
                  mx: 'auto',
                  width: '100%',
                  '& .react-multiple-carousel__arrow': {
                    zIndex: 3,
                    borderRadius: 0,
                    height: '100%',
                    transition:
                      'background-color 200ms, filter 200ms, color 200ms',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      '&::before': {
                        color: (currTheme) => currTheme.palette.grey[200],
                        fontSize: '4.5em',
                        fontWeight: 'bold',
                      },
                    },
                    '&::before': {
                      transition: 'font-size 200ms ease',
                      color: (currTheme) => currTheme.palette.common.white,
                      fontSize: '4em',
                      fontWeight: 'regular',
                      filter: (currTheme) =>
                        `drop-shadow(0.08em 0.08em .1em ${currTheme.palette.common.black})`,
                    },
                  },
                  '& .react-multiple-carousel__arrow--left': {
                    left: '0',
                    backgroundImage: (currTheme) =>
                      `linear-gradient(90deg, ${currTheme.palette.mainBackground} 0%, rgba(84,50,168,0) 63%)`,
                  },
                  '& .react-multiple-carousel__arrow--right': {
                    right: '0',
                    backgroundImage: (currTheme) =>
                      `linear-gradient(270deg, ${currTheme.palette.mainBackground} 0%, rgba(7, 2, 21, 0) 58.67%)`,
                  },
                }}>
                <MultiCarousel
                  swipeable={true}
                  draggable={isMobile ? 'false' : 'true'}
                  showDots={false}
                  responsive={responsiveCarouselBreakpoints}
                  partialVisible={true}
                  infinite={false}
                  autoPlay={false}
                  removeArrowOnDeviceType={[
                    'mobile',
                    'extraSmallmobile',
                    'superExtraSmallMobile',
                  ]}
                  keyBoardControl={true}>
                  {category.events.map((event) => (
                    <EventCard key={event.id} {...event} />
                  ))}
                </MultiCarousel>
              </Box>
            </Box>
          ))
        ) : (
          <>{skeletonRows(responsiveCarouselBreakpoints)}</>
        )}
      </Stack>
    </>
  );
};

EventsByIndex.defaultProps = {
  something: '',
};

EventsByIndex.propTypes = {
  something: PropTypes.string,
};

export default EventsByIndex;
