export const getResponsiveObjectForEvents = (theme) => ({
  largeDesktop: {
    breakpoint: {
      max: 5000,
      min: theme.breakpoints.values.lg,
    },
    items: 4,
    slidesToSlide: 4,
    partialVisibilityGutter: 10,
  },
  desktop2: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md + 150,
    },
    items: 4,
    slidesToSlide: 4,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: {
      max: theme.breakpoints.values.md + 150,
      min: theme.breakpoints.values.md,
    },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 40,
  },
  semi2Mobile: {
    breakpoint: {
      max: theme.breakpoints.values.md,
      min: theme.breakpoints.values.sm + 150,
    },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 30,
  },
  semiMobile: {
    breakpoint: {
      max: theme.breakpoints.values.sm + 150,
      min: theme.breakpoints.values.sm,
    },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 5,
  },
  mobile: {
    breakpoint: {
      max: theme.breakpoints.values.sm,
      min: 375, // this is 0
    },
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 30,
  },
  extraSmallmobile: {
    breakpoint: {
      max: 375,
      min: 325,
    },
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 10,
  },
  superExtraSmallMobile: {
    breakpoint: {
      max: 325,
      min: theme.breakpoints.values.xs, // this is 0
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 120,
  },
});

export const getResponsiveObjectForTestimonies = (theme) => ({
  desktop: {
    breakpoint: { max: 3000, min: theme.breakpoints.values.lg },
    items: 3,
  },
  tablet: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md,
    },
    items: 2,
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.md, min: 0 },
    items: 1,
  },
});

export const getResponsiveObjectForBannersCarosuel = (theme, bkDesk, bkTablet, bkMobile) => ({
  desktop: {
    breakpoint: { max: 3000, min: theme.breakpoints.values.lg },
    items: bkDesk,
  },
  tablet: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md,
    },
    items: bkTablet,
  },
  tabletSmall: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md,
    },
    items: bkTablet,
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.md, min: 0 },
    items: bkMobile,
  },
});

export const getResponsiveObjectForBannerAlianza = (theme, bkDesk, bkTablet, bkTabletSm, bkMobile) => ({
  desktop: {
    breakpoint: { max: 3000, min: theme.breakpoints.values.lg },
    items: bkDesk,
  },
  tablet: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md,
    },
    items: bkTablet,
  },
  tabletSmall: {
    breakpoint: {
      max: theme.breakpoints.values.md,
      min: theme.breakpoints.values.sm,
    },
    items: bkTabletSm,
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.sm, min: 0 },
    items: bkMobile,
  },
});

export const getResponsiveObjectForFormula1 = (theme) => ({
  desktop: {
    breakpoint: {
      max: 3000,
      min: theme.breakpoints.values.md,
    },
    items: 1,
    showDots: false
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.md, min: 0 },
    items: 1,
  },
});

export const getResponsiveObjectForCarousel = (theme) => ({
  desktop: {
    breakpoint: {
      max: 3000,
      min: theme.breakpoints.values.md,
    },
    items: 2,
    showDots: false
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.md, min: 0 },
    items: 1,
  },
});

export const getResponsiveObjectForVenues = (theme) => ({
  desktop: {
    breakpoint: { max: 3000, min: theme.breakpoints.values.lg },
    items: 3,
  },
  tablet: {
    breakpoint: {
      max: theme.breakpoints.values.lg,
      min: theme.breakpoints.values.md,
    },
    items: 2,
  },
  mobile: {
    breakpoint: { max: theme.breakpoints.values.md, min: 0 },
    items: 1,
  },
});
