import React, { useContext, useEffect } from 'react';
// UI
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Grid,
  Stack,
} from '@mui/material';
// Components & Utils
import HeroBanner from '../components/landing/HeroBanner';
import VideoContainer from '../components/shared/VideoContainer';
import ImageCard from '../components/shared/ImageCard';
import VenueItem from '../components/carousel/VenueItem';
import Carousel from '../components/carousel/Carousel';
import {
  bookingServices,
  howItWorks,
  linkingBoxes,
  venuesInfo,
} from '../utils/landingData';
import VideoAndItemsSection from '../components/landing/VideoAndItemsSection';
import DownloadAppSection from '../components/shared/DownloadAppSection';
import FAQSection from '../components/shared/FAQSection';
import TestimoniesSections from '../components/landing/TestimoniesSections';
import HalfCircle from '../components/shared/HalfCircle';
import ViewContainer from '../components/shared/ViewContainer';
import useElementOnScreen from '../hooks/useElementOnScreen';
import { menuOptions } from '../utils/menuOptions';
import { UIContext } from '../providers/UIProvider';
import { getResponsiveObjectForVenues } from '../utils/carouselResponsive';
import { useCities } from '../hooks/requests';
import { CTA } from '../components/landing/CTA';
import useDocumentTitle from '../hooks/useDocumentTitle';
import AlianzaItem from '../components/landing/AlianzaItem';
import { alianzaData } from '../utils/alianzaData';
import EventsByIndex from './events/EventsByIndex';
import { CTAHero } from '../components/landing/CTAHero';
import BannerAlianza from '../components/landing/BannerAlianza';

const defaultElementOnScreenOptions = {
  rootMargin: '0px',
  threshold: 0.95,
};

export const Landing = () => {
  const theme = useTheme();
  useDocumentTitle('Oasis App - Renta de Palcos');
  const { setNavbarBackgroundColor } = useContext(UIContext);
  const [bannerRef, isBannerRefVisible] = useElementOnScreen(
    defaultElementOnScreenOptions
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: citiesData } = useCities({
    id: 'cities',
  });

  useEffect(() => {
    if (!isBannerRefVisible) {
      setNavbarBackgroundColor(theme.palette.lightBackground);
    } else {
      setNavbarBackgroundColor('transparent');
    }
  }, [
    isBannerRefVisible,
    setNavbarBackgroundColor,
    theme.palette.lightBackground,
  ]);

  const responsiveCarouselBreakpoints = getResponsiveObjectForVenues(theme);
  const cities = citiesData
    ? citiesData.map((c) => c.name).join(', ')
    : 'diferentes ciudades';

  return (
    <ViewContainer>
      <div ref={bannerRef}>
        <HeroBanner />
      </div>
      <HalfCircle isLeftCircle left='-2em' top={isMobile ? '70vh' : '120vh'} />
      <HalfCircle right='-2em' bottom='260vh' opacity='.9' />
      <HalfCircle right='-2em' bottom='20em' />
      <Box sx={{
        backgroundColor: '#ffffff',
        padding: isMobile ? '1em 0em' : '1em 4em',
        marginBottom: '3em',
        position: 'relative'
      }}>
        <Box textAlign='center'>
          <Typography variant='p' fontSize='30px' fontFamily='MuseoModerno' color='#949494'><b>Alianzas y medios</b></Typography>
        </Box>
        <BannerAlianza
          data={alianzaData}
          bkDesk={7}
          bkTablet={4}
          bkTabletSm={4}
          bkMobile={2}
          itemBanner={AlianzaItem}
          dots={false}
        />
      </Box>
      <Box
        id={menuOptions.HOWITWORKS.id}
        sx={{
          mx: 'auto',
          mb: '3em',
          position: 'relative',
          zIndex: 1,
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
        }}>
        <EventsByIndex />
        <Box textAlign='center' margin='4em 0'>
          <CTAHero isFullWidth={isMobile} textCta='Ver más' />
        </Box>
        <Box
          sx={{
            px: 3,
            mt: isMobile ? 10 : 15,
            mb: 10,
            textAlign: 'center'
          }}>
          <Typography
            variant='p'
            fontWeight='500'
            fontSize='50px'
            sx={{
              my: isMobile ? 5 : 10,
              textAlign: 'center'
            }}>
            ¡Desde Oasis todo el mundo se ve mejor!
          </Typography>
          <VideoContainer title='¿Qué es Oasis?' embedID='mR3NSblqaX4' />
        </Box>
        <Box
          sx={{
            px: 3,
            mt: isMobile ? 10 : 25,
            mb: 10,
          }}>
          <Typography
            variant='h2'
            align='center'
            sx={{
              my: isMobile ? 5 : 10,
            }}>
            ¿Cómo funciona?
          </Typography>
          <Grid container spacing={4}>
            {howItWorks.map((item) => (
              <Grid key={item.id} item xs>
                <ImageCard
                  {...item}
                  shadow={theme.palette.shadowWhite}
                  imageMaxWidth={isMobile ? '8em' : '10em'}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box textAlign='center' padding='30px 0'>
          <Typography
            variant='p'
            fontSize='25px'
            fontWeight='500'
            variantMapping={{ h5: 'p' }}
            align='center'
            sx={{ mt: 10, mb: 5, px: 3 }}>
            {`Estamos en ${cities}\n¡Muy pronto nos verás en otras ciudades!`}
          </Typography>
        </Box>
        <Carousel
          carouselItems={venuesInfo}
          Item={VenueItem}
          responsive={responsiveCarouselBreakpoints}
        />
        <Box id={menuOptions.BOOKING.id}>
          <VideoAndItemsSection
            isVideoToLeft={true}
            sectionTitle='¡Renta un palco para tu evento'
            sectionSupportingText='Rentar un palco para ver cualquier deporte, artista o evento nunca había sido tan fácil como con Oasis. Desde nuestra web y App te ofrecemos la exclusividad de rentar un palco por evento o por toda una temporada. ¡Vive la Experiencia Oasis!'
            videoEmbedID='sdvnakxEg3U'
            videoTitle='Tutorial Huéspedes Oasis'
            titleData='Beneficios de Rentar un Palco en Oasis'
            variantTitleData='h3'
            itemsData={bookingServices}
            showCTA
            textCTA='Buscar un evento aquí'
          />
        </Box>

        <Box id={menuOptions.ENTAILMENT.id}>
          <VideoAndItemsSection
            isVideoToLeft={false}
            sectionTitle='¿Cómo ganar con tu palco en Oasis?'
            sectionSupportingText='Exclusivamente a través de nuestra App podrás vincular tu(s) palco(s) con Oasis de manera fácil y segura, vinculando tu inmueble crearás tu palco como un activo con la posibilidad de recibir ingresos constantes. Nosotros nos encargamos de todo, desde la recolección de pases, llaves y plateas hasta la devolución del Kit de entradas.' 
            videoEmbedID='0WckusI6NCA'
            videoTitle='¿Cómo subir tu palco a Oasis?'
            titleData='Beneficios de Registrar tu Palco en Oasis'
            variantTitleData='p'
            fontsizeTitleData='40px'
            fontweightTitleData='500'
            itemsData={linkingBoxes}
          />
        </Box>

        <Box sx={{ my: 12 }}>
          <DownloadAppSection />
        </Box>
        <Stack alignItems='center' sx={{ maxWidth: 'sm', mx: 'auto', px: 3 }}>
          <CTA isFullWidth={true} textCta='Tambien puedes ¡rentar un palco en nuestra web!' />
        </Stack>
      </Box>
      <TestimoniesSections />
      <FAQSection />
    </ViewContainer>
  );
};
