import benefit01 from '../assets/formula1/benefits/benefit_01.svg';
import benefit02 from '../assets/formula1/benefits/benefit_02.svg';
import benefit03 from '../assets/formula1/benefits/benefit_03.svg';
import benefit04 from '../assets/formula1/benefits/benefit_04.svg';
import benefit05 from '../assets/formula1/benefits/benefit_05.svg';
import benefit06 from '../assets/formula1/benefits/benefit_06.svg';
import benefit07 from '../assets/formula1/benefits/benefit_07.svg';
import benefit08 from '../assets/formula1/benefits/benefit_08.svg';
import benefit09 from '../assets/formula1/benefits/benefit_09.svg';
import benefit12 from '../assets/formula1/benefits/benefit_12.svg';
import benefit13 from '../assets/formula1/benefits/benefit_13.svg';
import benefit14 from '../assets/formula1/benefits/benefit_14.svg';
import benefit15 from '../assets/formula1/benefits/benefit_15.svg';
import benefit16 from '../assets/formula1/benefits/benefit_16.svg';
import benefit17 from '../assets/formula1/benefits/benefit_17.svg';

import box01 from '../assets/formula1/carousel/1.png';
import box02 from '../assets/formula1/carousel/2.png';
import box03 from '../assets/formula1/carousel/3.png';
import box04 from '../assets/formula1/carousel/4.png';
import box05 from '../assets/formula1/carousel/5.png';
import box06 from '../assets/formula1/carousel/6.png';
import box07 from '../assets/formula1/carousel/7.png';
import box08 from '../assets/formula1/carousel/8.png';
import box09 from '../assets/formula1/carousel/9.png';

export const benefitsData = [
  {
    id: 'benefit01',
    image: benefit01,
    alt: 'Precio',
    supportingText: '140,000 USD + IVA.',
  },
  {
    id: 'benefit02',
    image: benefit02,
    alt: 'Gorra',
    supportingText: 'Gorra conmemorativa del evento por persona.',
  },
  {
    id: 'benefit03',
    image: benefit03,
    alt: 'Suites',
    supportingText: 'Suites privadas de 40 personas .',
  },
  {
    id: 'benefit04',
    image: benefit04,
    alt: 'Lanyard',
    supportingText: 'Lanyard conmemorativo del Evento por abono.',
  },
  {
    id: 'benefit05',
    image: benefit05,
    alt: 'Zona Main GrandStand',
    supportingText: 'Boletos ubicados en Zona Amarilla.',
  },
  {
    id: 'benefit06',
    image: benefit06,
    alt: 'Boleto Conmemorativo',
    supportingText: 'Boleto conmemorativo por día.',
  },
  {
    id: 'benefit07',
    image: benefit07,
    alt: 'Mobiliario',
    supportingText:
      'Cada suite incluye mobiliario, alfombra y tv con señal del Evento.',
  },
  {
    id: 'benefit08',
    image: benefit08,
    alt: 'Regalo',
    supportingText: '1 regalo conmemorativo por suite.',
  },
  {
    id: 'benefit09',
    image: benefit09,
    alt: 'Buffet',
    supportingText:
      'Servicio de buffet gourmet, desayuno, snacks y comida de 9:00 a 18:00 hrs los 3 dias del evento.',
  },
  {
    id: 'benefit12',
    image: benefit12,
    alt: 'Branding',
    supportingText: 'Pared con branding.',
  },
  {
    id: 'benefit13',
    image: benefit13,
    alt: 'Estacionamientos',
    supportingText: '3 pases de estacionamiento remoto por suite.',
  },
  {
    id: 'benefit14',
    image: benefit14,
    alt: 'Identificación',
    supportingText: 'Identificación de la suite con el número correspondiente.',
  },
  {
    id: 'benefit15',
    image: benefit15,
    alt: 'Caja conmemorativa',
    supportingText: '1 caja conmemorativa por suite.',
  },
  {
    id: 'benefit16',
    image: benefit16,
    alt: 'Pit Lane Walk',
    supportingText:
      'Un acceso por cada 2 boletos al Pit Lane Walk el dia jueves de la semana del evento.',
  },
  {
    id: 'benefit17',
    image: benefit17,
    alt: 'Baños privados',
    supportingText: 'Zona de baños exclusivos para la zona.',
  },
];

export const f1BoxImages = [
  {
    id: 'box01',
    altText: 'box01',
    srcImage: box01,
  },
  {
    id: 'box02',
    altText: 'box02',
    srcImage: box02,
  },
  {
    id: 'box03',
    altText: 'box03',
    srcImage: box03,
  },
  {
    id: 'box04',
    altText: 'box04',
    srcImage: box04,
  },
  {
    id: 'box05',
    altText: 'box05',
    srcImage: box05,
  },
  {
    id: 'box06',
    altText: 'box06',
    srcImage: box06,
  },
  {
    id: 'box07',
    altText: 'box07',
    srcImage: box07,
  },
  {
    id: 'box08',
    altText: 'box08',
    srcImage: box08,
  },
  {
    id: 'box09',
    altText: 'box09',
    srcImage: box09,
  },
];
