import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// Components & Utils
import { useBooking } from '../../hooks/requests';
import IconText from '../../components/shared/IconText';
// UI
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { parseDate } from '../../utils/util';
import Carousel from '../../components/carousel/Carousel';
import VenueItem from '../../components/carousel/VenueItem';
import { getResponsiveObjectForCarousel } from '../../utils/carouselResponsive';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import moment from "moment";

const getBoxPicturesArray = (pictureUrlArray = []) =>
  pictureUrlArray.map((srcImage, index) => ({
    id: index,
    altText: '',
    srcImage,
  }));

/**
 * @function BoxBooked
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const BoxBooked = () => {
  const { bookingID } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const responsiveCarouselBreakpoints = getResponsiveObjectForCarousel(theme);

  const { data: booking } = useBooking(bookingID, {
    cacheTime: 5000,
    onError: () => console.log('ERROR CARGANDO Booking'),
  });

  useDocumentTitle(
    `Oasis - Reserva ${booking?.event?.name ? 'para ' + booking.event?.name : ''}`
  );

  const pictures = booking?.box?.pictureUrl;

  const boxPictures = useMemo(() => getBoxPicturesArray(pictures), [pictures]);

  return (
    <Box sx={{ width: '100%', mx: 'auto', maxWidth: 'md', my: 5, px: 3 }}>
      {booking ? (
        <Stack spacing={3}>
          <Typography variant='h1' sx={{ mb: 3 }}>
            {booking.event?.name}
          </Typography>
          <Stack spacing={1}>
            <Typography variant={isMobile ? 'body2' : 'body1'}>
              Reservación: <strong>{booking.key}</strong>
            </Typography>
            <IconText
              text={booking.event?.venue?.name}
              Icon={<LocationOnIcon color='secondary' />}
              typographyProps={{
                variant: isMobile ? 'body2' : 'body1',
              }}
            />
            {booking.event?.startsAt && (
              <IconText
                text={ parseDate(moment(booking.event.startsAt).add(5, 'hours').toString(), 'dayLongMonthYearTime') }
                Icon={<CalendarTodayIcon color='secondary' />}
                typographyProps={{
                  variant: isMobile ? 'body2' : 'body1',
                }}
              />
            )}
            {booking.totalSeats && (
              <IconText
                text={`${booking.totalSeats} asientos`}
                Icon={<EventSeatIcon color='secondary' />}
                typographyProps={{
                  variant: isMobile ? 'body2' : 'body1',
                }}
              />
            )}
          </Stack>
          {booking.box?.detail && (
            <>
              <Typography variant='h2'>Descripción</Typography>
              <Typography variant={isMobile ? 'body2' : 'body1'}>
                {booking.box.detail}
              </Typography>
            </>
          )}
          {booking.box?.accessIndications && (
            <>
              <Typography variant='h2'>Indicaciones de acceso</Typography>
              <Typography variant={isMobile ? 'body2' : 'body1'}>
                {booking.box.accessIndications}
              </Typography>
            </>
          )}
          <Stack sx={{ py: 5 }}>
            <Carousel
              carouselItems={boxPictures}
              Item={(props) => (
                <VenueItem
                  {...props}
                  elementHeight='18em'
                  containerSX={{
                    px: 3,
                    '& img': { objectFit: 'cover !important' },
                  }}
                />
              )}
              responsive={responsiveCarouselBreakpoints}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
};

BoxBooked.defaultProps = {
  something: '',
};

BoxBooked.propTypes = {
  something: PropTypes.string,
};

export default BoxBooked;
