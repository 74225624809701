import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box } from '@mui/material';

/**
 * @function AlianzaItem
 * @author Axel
 * @version 1.0
 */
const AlianzaItem = ({ image, alt, widthImg }) => {
  return (
    <Box
      sx={{
        height: '200px',
        maxWidth: '100%',
        mx: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <img src={image} alt={alt} style={{maxWidth: widthImg, margin: '0 auto'}}/>
    </Box>
  );
};

AlianzaItem.defaultProps = {
  image: '',
  alt: '',
  widthImg: '100%'
};

AlianzaItem.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  widthImg: PropTypes.string
};

export default AlianzaItem;